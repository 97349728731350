<template>
  <div class="admin-list">
    <h4 class="list-title">{{ $lang.app.faq }}</h4>
    <div class="admin-navbar align-right">
      <router-link to="/admin/faq/create" class="action-button">{{ $lang.app.create }}</router-link>
    </div>
    <div class="admin-table">
      <table>
        <thead>
        <tr>
          <th class="key">{{ $lang.app.category }}</th>
          <th>{{ $lang.app.question }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="faqItem in faq" :key="faqItem.id">
          <td class="key">{{ typeLabel(faqItem.type) }}</td>
          <td>{{ $lang.$translate({ru: faqItem.titleRu, kg: faqItem.titleKg}) }}</td>
          <td>
            <div class="action-buttons">
              <router-link :to="`/admin/faq/${faqItem.id}`"
                           class="action-button" :title="$lang.app.view"><i class="bi-info-square"></i></router-link>
              <router-link :to="`/admin/faq/${faqItem.id}/edit`"
                           class="action-button" :title="$lang.app.edit"><i class="bi-pencil-square"></i></router-link>
              <button class="action-button" :title="$lang.app.delete" @click="deleteRequest(faqItem.id)"><i class="bi-x-square"></i></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <DeleteModal v-if="deleteId"
                 @close="deleteId = null"
                 @delete="deleteResource"
    />
  </div>
</template>
<script>
import DeleteModal from '../../../components/admin/DeleteModal'
export default {
  components: {
    DeleteModal
  },
  data() {
    return {
      faq: [],
      deleteId: null,
    }
  },
  watch: {
    '$route'() {
      this.fetchResources()
    }
  },
  methods: {
    typeLabel(value) {
      switch (value) {
        case 'SCHOOL': return this.$lang.app.school
        case 'PRESCHOOL': return this.$lang.app.preschool
      }
    },
    fetchResources() {
      this.$axios.get(`/qna`).then(({data}) => {
        this.faq = data
      })
    },
    deleteRequest(id) {
      this.deleteId = id
    },
    deleteResource() {
      this.$axios.delete(`/qna?id=${this.deleteId}`).then(() => {
        this.deleteId = null
        this.fetchResources()
        this.$snotify.success(this.$lang.app.resource_deleted_successfully);
      })
    }
  },
  mounted() {
    this.fetchResources()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/table.css';
</style>